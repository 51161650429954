import React from 'react';
import logoImage from '../assets/logo/blue.svg';
import MenuImg from '../assets/icons/blueMenu.svg';
import MainLayout from '../common/layouts/MainLayout';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { useClass } from './useClass';
import ClassFinishedAnim from './ClassFinishedAnim';
import './Class.css';

function Class(): JSX.Element {
    const { getMQid, is_horiz } = useMediaQueryWrap();

    const rid = getMQid();

    const {
        render_video,
        render_camera,
        render_ab_ruler_during,
        render_ab_ruler_select,
        render_ab_ruler_match,
        render_next_button,
        render_back_button,
        render_answers,
        render_puzzle,
        is_puzzle,
        render_paint_maze,
        is_paint_maze,
        render_maze,
        is_maze,
        render_ab_ruler_prog,
        render_mic_prog,
        render_mic,
        classFinishedAnim,
        exit_class_and_navigate,
        render_class_progress,
    } = useClass();

    const render = () => {
        if (is_puzzle()) return render_puzzle();
        if (is_paint_maze()) return render_paint_maze();
        if (is_maze()) return render_maze();

        if (!is_horiz) {
            return (
                <div className="classContentVert">
                    {render_class_progress()}
                    {render_video()}
                    {render_ab_ruler_during()}
                    {render_ab_ruler_select()}
                    {render_ab_ruler_match()}
                    {render_ab_ruler_prog()}
                    {render_answers()}
                    <div className="navButtonVerticalWrap">
                        {render_back_button()}
                        {render_camera()}
                        {render_mic()}
                        {render_mic_prog()}
                        {render_next_button()}
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div className="classContentHoriz">
                        {render_class_progress()}
                        {render_video()}
                        {render_back_button()}
                        {render_next_button()}
                        <div className="classHorizAbsAction">
                            {render_mic()}
                            {render_mic_prog()}
                            {render_camera()}
                        </div>
                    </div>
                    <div className="horizActions">
                        {render_ab_ruler_during()}
                        {render_ab_ruler_select()}
                        {render_ab_ruler_match()}
                        {render_ab_ruler_prog()}
                        {render_answers()}
                    </div>
                </>
            );
        }
    };

    return (
        <MainLayout can_url="/class" id={`class_${rid}`} logo={logoImage} appMenuLogo={MenuImg} in_a_course>
            <div className="classContent">{render()}</div>
            <ClassFinishedAnim open={classFinishedAnim} finish_cb={() => exit_class_and_navigate()} />
        </MainLayout>
    );
}

export default Class;
